<template>
  <div>
    <div v-if="hasCompany">
      <div class="pb-3">
        <b-button class="ml-1" @click="refresh" variant="dark">
          <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
          <span class="ml-1">{{ $t('LIST_REFRESH_LABEL') | capitalize }}</span>
        </b-button>

        <!-- clear button -->
        <b-button class="ml-1" @click="clear" variant="dark">
          <b-icon-x></b-icon-x>
          <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
        </b-button>
        <!-- clear button -->
      </div>

      <div class="mb-1">
        <!-- List page filter -->
        <customer-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter"
                              v-model="filter"></customer-list-filter>
        <!-- List page filter -->

        <!-- Page size -->
        <div class="d-inline-block pl-1 w-25">
          <page-size v-model="size"></page-size>
        </div>
        <!-- Page size -->
      </div>

      <b-table
          id="customer-table"
          ref="customerTable"
          @row-selected="handleSelection"
          v-model="items"
          striped
          hover
          bordered
          small
          table-variant="dark"
          selectable
          :select-mode="selectMode"
          :filter="filter"
          :current-page="page"
          :sort-by="sort"
          :per-page="size"
          :busy.sync="isBusy"
          :fields="fields"
          :items="listAll">
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('PROFILES')})  | capitalize }}</strong>
          </div>
        </template>

        <template #head(multiselect)="">
          <input :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
        </template>

        <template #cell(multiselect)="data">
          <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
        </template>

        <template #head(ccodigocliente)="">
          <span>{{ $t('CUSTOMER_CODE_LABEL')  | capitalize }}</span>
        </template>

        <template #cell(ccodigocliente)="data">
          <div style="text-align: left; vertical-align: center">
            <span>{{ data.item.ccodigocliente }}</span>
          </div>
        </template>

        <template #head(crazonsocial)="">
          <span>{{ $t('CUSTOMER_NAME_LABEL')  | capitalize }}</span>
        </template>

        <template #cell(crazonsocial)="data">
          <div style="text-align: left; vertical-align: center">
            <span>{{ data.item.crazonsocial }}</span>
          </div>
        </template>

        <template #head(crfc)="">
          <span>{{ $t('CUSTOMER_RFC_LABEL')  | capitalize }}</span>
        </template>

        <template #cell(crfc)="data">
          <div style="text-align: left; vertical-align: center">
            <span>{{ data.item.crfc }}</span>
          </div>
        </template>

        <template #head(options)="">
          <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize }}</span>
        </template>

        <template #cell(options)="data">
          <div style="text-align: center">
            <b-dropdown size="sm" id="dropdown-1" right>
              <b-dropdown-item v-has-permission="'CUSTOMER_LIST_FILES_PERMISSION'" @click="showFileModal(data.item.cidclienteproveedor)" >
                {{$t('LIST_FILES_LABEL') | capitalize}}
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>

      <b-container fluid>
        <b-row>
          <b-col>
            <b-pagination
                v-model="page"
                :total-rows="totalRows"
                :per-page="size"
                pills
                aria-controls="customer-table"
            ></b-pagination>
          </b-col>
          <b-col class="text-right">
            {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
          </b-col>
        </b-row>
      </b-container>

      <b-modal id="digital-file-modal" :title="$t('FILE_MODAL_TITLE')" size="xl" hide-footer>
        <files-resume :id="this.id" :name="this.id"></files-resume>
      </b-modal>
    </div>
    <div v-else>
      {{ $t('NO_COMPANY_SELECTED') | capitalize }}
    </div>
  </div>
</template>

<script>
import {Company, Table} from "@/mixins";
import eventBus from "@/events";
import Constants from "@/constants";
import PageSize from "@/components/PageSize";
import CustomerListFilter from "@/customers/CustomerListFilter";
import Customers from "@/customers/index";
import FilesResume from "@/files/FilesResume";

export default {
  name: "CustomerList",
  components: {PageSize, CustomerListFilter, FilesResume},
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  mixins: [Table, Company],
  mounted() {
    if (eventBus) {
      eventBus.$on(Constants.EVENT_ON_PROFILE_LIST, (totalElements) => {
        this.totalRows = totalElements;
        if (this.currentlySelected) {
          this.originals = this.currentlySelected;
        }
      });

      eventBus.$on('COMPANY_CHANGED', (company) => {
        this.currentCompany$ = company;
        this.refresh();
      });
    }
  },
  methods: {
    refresh() {
      this.$refs.customerTable.refresh()
    },
    showFileModal(id) {
      this.id = `${this.currentCompany}-${id}`;
      this.$bvModal.show('digital-file-modal');
    },
  },
  computed: {
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'ccodigocliente', sortable: true, tdClass: 'customTd'},
        {key: 'crazonsocial', sortable: true, tdClass: 'customTd'},
        {key: 'crfc', sortable: true, tdClass: 'customTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    tableRef() {
      return this.$refs.customerTable;
    },
    controller() {
      return Customers;
    },

  },
  data() {
    return {
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>