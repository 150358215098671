<template>
  <div>
    <b-form class="mb-2" inline>
      <div :class="onlyActive ? 'w-100' : 'w-100 pr-1'">
        <b-form-input @blur="search" v-model="filter.text" class="w-100" :placeholder="$t('FILTER_SEARCH_LABEL', {catalog: $t('CUSTOMER')})  | capitalize"
                      id="search-input"></b-form-input>
      </div>
    </b-form>
  </div>
</template>

<script>
import {CapitalFilter, VModel} from "@/mixins";

export default {
  name: "CustomerListFilter",
  props: ['onlyActive'],
  mixins: [VModel, CapitalFilter],
  data() {
    return {
      filter: {}
    }
  },
  methods: {
    search() {
      if(this.onlyActive) {
        this.filter.status = true;
      }
      this.$emit('search', this.filter);
    }
  }
}
</script>

<style scoped>

</style>
